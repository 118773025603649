.hero {
  background-image: url('../public/img/i1.jpg');
  background-size: cover;
  height: 100vh;
  color: #fff;
}

footer .list-inline-item a {
  text-decoration: none;
}

footer .list-inline-item a:hover {
  text-decoration: underline;
}
